import React from 'react'
import classNames from 'classnames'
import { divide, range, minBy } from 'lodash'
import { currency } from 'utils/utils'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'formik-material-ui'
import { Field } from 'formik'

import { MenuItem } from '@material-ui/core'
import 'react-credit-cards/es/styles-compiled.css'
import useStyles from './style'
import { FormattedMessage } from 'react-intl'

export default ({ installment, total, items, isNegotiation }) => {
  const installmentValue = installment > 0 ? divide(total, installment) : total
  const classes = useStyles()

  let numberInstallment = items.length >= 4 ? 11 : ((items.length * 2) + 1)
  
  if (!isNegotiation) {
    const minInstallment = minBy(items, 'maximum_installment_number')
    numberInstallment = minInstallment ? (minInstallment.maximum_installment_number + 1) : 2
  }


  return (
    <>
      <Grid item xs={6} className={classes.item} >
        <Field
          name='installment'
          variant='outlined'
          color='primary'
          id='ckc-mdl-pag-inst'
          fullWidth
          label={<FormattedMessage id='payment-installments-label' />}
          select
          InputLabelProps={{ shrink: true }}
          component={TextField}>
          {range(1, numberInstallment).map(option => (
            <MenuItem key={option} value={option}>
              {option === 0 ? '' : option}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={4} className={classNames(classes.root, classes.disabled)}>
        <p><FormattedMessage id='payment-installments-total' values={{ currency: currency(installmentValue) }} /></p>
      </Grid>
    </>
  )
}