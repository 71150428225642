import React, {useEffect, useState} from 'react';
import "./styles.css";
import {useRecoilState, useRecoilValue} from "recoil";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import WalletSaveCard from "./WalletSaveCard";
import WalletSelectionPreview from "./WalletSelectionPreview";
import WalletCardsList from "./WalletCardsList";
import * as _walletService from "../../service/wallet-service";
import PageLoading from "../PageLoading/PageLoading";
import {loadedCreditCardsState} from "../../recoil/atoms/loadedCreditCardsState";
import {walletShowChangeSubsState} from "../../recoil/atoms/walletShowChangeSubsState";
import WalletChangeSubs from "./WalletChangeSubs";
import * as mensagem from '../../components/shared/Message.js';
import {walletCardReloadState} from "../../recoil/atoms/walletCardReloadState";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {Dialog,} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {useTheme, withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const antifraudeOrg = process.env.REACT_APP_GETNET_ANTIFRAUDE_ORG ?? "1snn5n9w"

function WalletDialog(props) {
  const {
    onSelectCard = card => {},
    selectedCard = null,
    show = false
  } = props;
  const [loadingCards, setLoadingCards] = useState(false);
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const [showAddCard, setShowAddCard] = useState(false);
  const [showChangeSubs, setShowChangeSubs] = useRecoilState(walletShowChangeSubsState);
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const selectedCreditCard = selectedCard ? creditCards.find(c => c.cardId === selectedCard) : null;
  const [loaded, setLoaded] = useRecoilState(loadedCreditCardsState);
  const [reload, setReload] = useRecoilState(walletCardReloadState);
  const [open, setOpen] = React.useState(selectedCreditCard === null);
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (creditCards.length === 0 && showAddCard) setShowAddCard(true);
  }, [creditCards, showAddCard])

  useEffect(() => {
    if (show && !open) {
      setOpen(true);
      setShowAddCard(false);
      setShowChangeSubs(false);
    }
  }, [show])

  useEffect(() => {
    if (open && false === loaded) {
      if (loadingCards) return;
      setLoadingCards(true)
      _walletService.cartaoListar(
        selectedMatricula?.unidade_Id,
        selectedMatricula?.responsavelFinanceiro
      )
        .then(async resp => {
          if (resp.ok) {
            return await resp.json();
          } else {
            const json = await resp.json();
            if (json?.messages) {
              (json?.messages || []).map(m => mensagem.ExibeMensagem(m?.text, String(m?.typeMessage)))
            }
            setOpen(false);
          }
        })
        .then(({value: {cards}}) => {
          if (creditCards && creditCards.length) {
            setCreditCards(cards.map(card => {
              const oldCard = creditCards.find(c => c.cardId === card.cardId);
              if (oldCard) {
                // restaura numberToken para  evitar uma nova verificação no cartão
                card.numberToken = oldCard.numberToken
              }
              return card
            }))
          } else {
            setCreditCards(cards ?? []);
          }
          setLoaded(true);
          if (!(cards || []).length) setShowAddCard(true);
        })
        .finally(() => setLoadingCards(false))
    }
  }, [open, loaded, creditCards])

  useEffect(() => {
    if (reload) {
      setLoaded(false);
      setReload(false)
    }
  }, [reload]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowChangeSubs(false);
    setShowAddCard(false);
  };

  const handleCloseChangeSubs = () => {
    setShowChangeSubs(false);
    setReload(true);
  };

  const handleCancel = () => {
    setShowAddCard(false);
  }

  const handleAddCard = () => {
    setShowAddCard(true);
  }

  const handleCardSelection = (card) => {
    if (onSelectCard) {
      onSelectCard({
        card_id: card.cardId,
        bin: card.bin,
        cardholder_name: card.cardholderName,
        expiration_month: card.expirationMonth,
        expiration_year: card.expirationYear,
        number_token: card.numberToken,
        month_year: `${card.expirationMonth}/${card.expirationYear}`,
        brand: card.brand,
      })
      handleClose();
    }
  }

  return (
    <div
      className="smo-wallet-modal-container panel-body"
      style={{padding: 'unset'}}
    >
      <noscript>
        <iframe
          style="width: 100px; height: 100px; border: 0; position:absolute; top: -5000px;"
          src={`https://h.online-metrix.net/fp/tags?org_id=${antifraudeOrg}&session_id=${sessionStorage.getItem('@SMO20/idResponsavel')}`}>
        </iframe>
      </noscript>

      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        fullScreen={fullScreen}
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {showAddCard ? "Novo Cartão" : showChangeSubs ? "Alterar recorrência" : "Cartões"}
        </DialogTitle>
        <DialogContent dividers>
          {loadingCards ? (
            <div style={{position: "relative", width: "100%", height: "100%", minHeight: "400px"}}>
              <PageLoading/>
            </div>
          ) : (
            <React.Fragment>
              {showAddCard ? (
                <WalletSaveCard
                  onClose={handleClose}
                  onCancel={handleCancel}
                />
              ) : (
                <>
                  {showChangeSubs && (
                    <WalletChangeSubs
                      onCancel={handleCloseChangeSubs}
                    />
                  )}
                  <WalletCardsList
                    hidden={showChangeSubs}
                    selectedCreditCard={selectedCreditCard}
                    onClickSelectCard={handleCardSelection}
                    onClickAddCard={handleAddCard}
                  />
                </>
              )}
            </React.Fragment>
          )}
        </DialogContent>
      </Dialog>

      <WalletSelectionPreview
        card={selectedCreditCard}
        onClick={handleClickOpen}
      />
    </div>
  );
}

export default WalletDialog;
