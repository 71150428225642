import React, {useEffect} from 'react';
import './styles.css';

function AccordionContracts(
  {
    id ,
    parentId = "accordionContracts",
    title = "",
    children,
    opened = false
  }
) {
  const [expanded, setExpanded] = React.useState(opened);

  useEffect(() => {
    if (opened && !expanded) setExpanded(true)
  }, [opened])

  return (
    <div className="card">
      <div
        className={[
          "card-header",
          expanded ? "expanded" : "",
        ].join(" ")}
        id={"headingContracts"+id}
      >
        <h2 className="mb-0">
          <button
            className="btn btn-link btn-block text-left"
            type="button"
            data-toggle="collapse"
            data-target={"#collapseContracts"+id}
            aria-expanded={expanded}
            aria-controls={"collapseContracts"+id}
            onClick={() => setExpanded(!expanded)}
          >
            {title}
          </button>

          <svg
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            fill="currentColor"
            data-toggle="collapse"
            data-target={"#collapseContracts"+id}
            aria-expanded={expanded}
            aria-controls={"collapseContracts"+id}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? (
              <path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"/>
            ) : (
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
            )}
          </svg>
        </h2>
      </div>

      <div
        id={"collapseContracts"+id}
        className={[
          "collapse",
          expanded ? "show" : "",
        ].join(" ")}
        aria-labelledby={"headingContracts"+id}
        data-parent={"#" + parentId}
      >
        <div className="card-body">
          {children}
        </div>
      </div>
    </div>
  );
}

export default AccordionContracts;
