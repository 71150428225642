import React, {useEffect, useState} from 'react';
import "./styles.css";
import {Modal} from "react-bootstrap";
import {useRecoilState} from "recoil";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import WalletSaveCard from "./WalletSaveCard";
import WalletSelectionPreview from "./WalletSelectionPreview";
import WalletCardsList from "./WalletCardsList";
import * as _walletService from "../../service/wallet-service";
import PageLoading from "../PageLoading/PageLoading";
import {loadedCreditCardsState} from "../../recoil/atoms/loadedCreditCardsState";
import {walletShowChangeSubsState} from "../../recoil/atoms/walletShowChangeSubsState";
import WalletChangeSubs from "./WalletChangeSubs";
import * as mensagem from '../../components/shared/Message.js';
import {walletCardReloadState} from "../../recoil/atoms/walletCardReloadState";
export const antifraudeOrg = process.env.REACT_APP_GETNET_ANTIFRAUDE_ORG ?? "1snn5n9w"

function WalletModal(props) {
  const {
    onSelectCard = card => {},
    selectedCard = null,
    show = false
  } = props;
  const [loadingCards, setLoadingCards] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const [showAddCard, setShowAddCard] = useState(false);
  const [showChangeSubs, setShowChangeSubs] = useRecoilState(walletShowChangeSubsState);
  const selectedCreditCard = selectedCard ? creditCards.find(c => c.cardId === selectedCard) : null;
  const [loaded, setLoaded] = useRecoilState(loadedCreditCardsState);
  const [reload, setReload] = useRecoilState(walletCardReloadState);

  useEffect(() => {
    if (creditCards.length === 0 && showAddCard) setShowAddCard(true);
  }, [creditCards, showAddCard])

  useEffect(() => {
    if (show && !open) {
      setOpen(true);
      setShowAddCard(false);
      setShowChangeSubs(false);
    }
  }, [show])

  useEffect(() => {
    if (open && false === loaded) {
      if (loadingCards) return;
      setLoadingCards(true)
      _walletService.cartaoListar(
        sessionStorage.getItem('@SMO20/unidade_Id'),
        sessionStorage.getItem('@SMO20/idResponsavel')
      )
        .then(async resp => {
          if (resp.ok) {
            return await resp.json();
          } else {
            const json = await resp.json();
            if (json?.messages) {
              (json?.messages || []).map(m => mensagem.ExibeMensagem(m?.text, String(m?.typeMessage)))
            }
            setOpen(false);
          }
        })
        .then(({value: {cards}}) => {
          if (creditCards && creditCards.length) {
            setCreditCards(cards.map(card => {
              const oldCard = creditCards.find(c => c.cardId === card.cardId);
              if (oldCard) {
                // restaura numberToken para  evitar uma nova verificação no cartão
                card.numberToken = oldCard.numberToken
              }
              return card
            }))
          } else {
            setCreditCards(cards ?? []);
          }
          setLoaded(true);
          if (!(cards || []).length) setShowAddCard(true);
        })
        .finally(() => setLoadingCards(false))
    }
  }, [open, loaded, creditCards])

  useEffect(() => {
    if (reload) {
      setLoaded(false);
      setReload(false)
    }
  }, [reload]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowChangeSubs(false);
    setShowAddCard(false);
  };

  const handleCloseChangeSubs = () => {
    setShowChangeSubs(false);
    setReload(true);
  };

  const handleCancel = () => {
    setShowAddCard(false);
  }

  const handleAddCard = () => {
    setShowAddCard(true);
  }

  const handleCardSelection = (card) => {
    if (onSelectCard) {
      onSelectCard({
        card_id: card.cardId,
        bin: card.bin,
        cardholder_name: card.cardholderName,
        expiration_month: card.expirationMonth,
        expiration_year: card.expirationYear,
        number_token: card.numberToken,
        month_year: `${card.expirationMonth}/${card.expirationYear}`
      })
      handleClose();
    }
  }

  return (
    <div
      className="smo-wallet-modal-container panel-body"
      style={{padding: 'unset'}}
    >
      <noscript>
        <iframe
          style="width: 100px; height: 100px; border: 0; position:absolute; top: -5000px;"
          src={`https://h.online-metrix.net/fp/tags?org_id=${antifraudeOrg}&session_id=${sessionStorage.getItem('@SMO20/idResponsavel')}`}>
        </iframe>
      </noscript>

      <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="smo-wallet-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {showAddCard ? "Novo Cartão" : showChangeSubs ? "Alterar recorrência" : "Cartões"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingCards ? (
            <div style={{position: "relative", width: "100%", height: "100%", minHeight: "400px"}}>
              <PageLoading/>
            </div>
          ) : (
            <React.Fragment>
              {showAddCard ? (
                <WalletSaveCard
                  onClose={handleClose}
                  onCancel={handleCancel}
                />
              ) : (
                <>
                  {showChangeSubs && (
                    <WalletChangeSubs
                      onCancel={handleCloseChangeSubs}
                    />
                  )}
                  <WalletCardsList
                    hidden={showChangeSubs}
                    selectedCreditCard={selectedCreditCard}
                    onClickSelectCard={handleCardSelection}
                    onClickAddCard={handleAddCard}
                  />
                </>
              )}
            </React.Fragment>
          )}
        </Modal.Body>
      </Modal>

      <WalletSelectionPreview
        card={selectedCreditCard}
        onClick={handleClickOpen}
      />
    </div>
  );
}

export default WalletModal;
