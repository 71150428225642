import React, {  } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";
import { useTheme } from "@material-ui/styles";

export default function AutomaticDebitDialogSummary({ items, automaticDebit }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.paymentDetail}>
        <PaymentDetail automaticDebit={automaticDebit} classes={classes} />
      </Grid>
    </React.Fragment>
  );
}

export const PaymentDetail = ({ automaticDebit }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={8}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Detalhes da Conta Bancária
      </Typography>
      <Grid container className={classes.details}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" className={classes.detail}>
            Banco:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">{automaticDebit.bank.value} - {automaticDebit.bank.text}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" className={classes.detail}>
            Agência:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">{automaticDebit.agency}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" className={classes.detail}>
            Número da conta com dígito:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">{automaticDebit.cc}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
