import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import { IconButton, Box, makeStyles } from '@material-ui/core'
import CardRegistration from '../CardRegistration'

const useStyles = makeStyles(() => ({
  paperScrollPaper: {
    maxHeight: '98%',
  },
  buttonClose: {
    position: 'absolute'
  },
  studantServices: {
    fontSize: '0.875rem',
  },
}))

export default ({ open, onClose, grouped }) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return
    onClose()
  }

  return (
    <Dialog
      maxWidth='sm'
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      classes={{ paper: !fullScreen && classes.paperScrollPaper }}
    >
      <Box display='flex' justifyContent='flex-end'>
        <IconButton className={classes.buttonClose} aria-label='close-registration' id='ico-mdl-reg-ser' onClick={handleClose} >
          <Close />
        </IconButton>
      </Box>
      <CardRegistration grouped={grouped} />
    </Dialog>
  )
}
