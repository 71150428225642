import React from 'react'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Checkout from './components/Checkout'
import Close from '@material-ui/icons/Close'
import { IconButton, Box ,  makeStyles } from '@material-ui/core'
import { paymentSelector } from 'modules/Finance/Pay/selectors/payments'
import { useDispatch, useSelector } from 'react-redux'
import { PAYMENT, IS_SUCCESS_REDE } from '../../constants'
import { apiResetAction } from 'utils/API/actions/status'
import { deleteCookie } from 'utils/persistCookie'

const useStyles = makeStyles(theme => ({
  paperScrollPaper: {
    maxHeight: '98%',
  },
  buttonClose: {
    position: 'absolute',
    right: 0
  }
}))

export default ({ open, onClose, items }) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { loading, waitingRede } = useSelector(paymentSelector)

  function handleClose(event, reason) {
    if (reason === 'backdropClick') return
    onClose()
    dispatch(apiResetAction(PAYMENT))
    deleteCookie(IS_SUCCESS_REDE)
  }

  return (
    <Dialog
      maxWidth={'sm'}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby='payment-dialog-title'
      classes={{ paper: !fullScreen && classes.paperScrollPaper }}
    > 
      <Box display='flex' justifyContent='flex-end'>
        <IconButton disabled={loading || waitingRede} className={classNames(!fullScreen && classes.buttonClose)} aria-label='close-payment' id='ico-mdl-pag-fch' onClick={handleClose} >
          <Close />
        </IconButton>
      </Box>
      <Checkout items={items} handleClose={handleClose} />
    </Dialog>
  )
}
