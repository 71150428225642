
import {  makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonsSmall: {
    marginBottom: 50,
  },
  button: {
    marginBottom: 10,
    marginLeft: theme.spacing(1),
    minWidth: 157,
    maxWidth: 300,
    height: 36,
  },
  form: {
    display: 'contents',
  },
  divider: {
    marginTop: 15,
    marginBottom: 10
  },
  item: {
    paddingLeft: theme.spacing(1),
    width: '100%',
    display: 'inline-block',
    minHeight: 85,
  },
  bands: {
    fontSize: '0.8rem'
  },
  errorMsg: {
    color: 'red'
  },
  cardType: {
    minHeight: 0,
    width: '50%',
    display: 'inline-block',
    paddingLeft: 8
  },
  imgCard: {
    width: '110%',
    maxWidth: 35,
    padding: '0.2rem', 
    opacity: 1
  },
  imgCardOpacity: {
    opacity: 0.3
  },
  input: {
    textTransform: 'uppercase',
    '&::placeholder': {
      textTransform: 'none',
    }
  },
  loading: {
    width: 40,
    height: 40
  },
  root: {
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'grey',
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    borderRadius: 4,
  },
  paymentTotal : {
    display: 'inline',
    fontWeight: 'bolder',
  },
  warningText: {
    margin: 0,
    listStyle: 'decimal',
    marginBottom: 15
  },
  labelInline: {
    display: 'inline-block',
    cursor: 'default'
  },
  link: {
    cursor: 'pointer'
  },
  paymentDetail: {
    width: '100%',
    marginTop: 10,
  }
}))
