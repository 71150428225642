import React, { Fragment } from 'react'
import { groupBy, map, split, omit, values } from 'lodash'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import { currency } from 'utils/utils'
import useStyles from './style'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/styles'
import SummaryTable from 'modules/Finance/components/SummaryTable'
import {BRANDS_CONFIG} from "../../../../../constants";

export default function OrderSummary({ items, payments }) {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const totalDocument = items.reduce((prev, curr) => prev + Number(curr.documentvalue), 0)
  const totalCorrect = items.reduce((prev, curr) => prev + Number(curr.correctvalue), 0)
  const totalFormatted = currency(totalCorrect)
  const groupByName = groupBy(map(items, item => (omit({ ...item, isSmall }, 'tableData'))), 'studentname')
  return (
    <React.Fragment>
      {values(groupByName).map((itemByGroup, index) => <SummaryTable item={itemByGroup} hasTitle key={index} />)}
      {!isSmall && <List>
        {totalCorrect < totalDocument &&
          <Fragment>
            <ListItem className={classes.listItem}>
              <ListItemText className={classes.amountDetail} primary='Valor' />
              <Typography variant='subtitle1' className={classes.amount}>
                {currency(items.reduce((prev, curr) => prev + Number(curr.documentvalue), 0))}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText className={classes.amountDetail} primary='Desconto' />
              <Typography variant='subtitle1' className={classes.amount}>
                {currency(payments.totalDiscount)}
              </Typography>
            </ListItem>
          </Fragment>
        }
        <ListItem className={classes.listItem}>
          <ListItemText className={classes.totalDetail} primary='Total' />
          <Typography variant='subtitle2' className={classes.total}>
            {totalFormatted}
          </Typography>
        </ListItem>
      </List>}
      <Grid container spacing={2} className={classes.paymentDetail}>
        <PaymentDetail payments={payments} classes={classes} />
      </Grid>
    </React.Fragment>
  )
}

export const PaymentDetail = ({ payments, receipt }) => {
  const classes = useStyles()
  const brandType = BRANDS_CONFIG()[payments.brand]

  return (
    <Grid item xs={12} sm={8} >
      <Typography variant='h6' gutterBottom className={classes.title}>
        Detalhes do pagamento
      </Typography>
      <Grid container className={classes.details}>
        {receipt && receipt.authorizationcode &&
          <Fragment>
            <Grid item xs={6}>
              <Typography variant='subtitle1' className={classes.detail} >Número da transação:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' >{receipt.authorizationcode}</Typography>
            </Grid>
          </Fragment>
        }
        {receipt && receipt.nsu && <Fragment>
          <Grid item xs={6}>
            <Typography variant='subtitle1' className={classes.detail} >NSU:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' >{receipt.nsu}</Typography>
          </Grid>
        </Fragment>
        }
        <Grid item xs={6}>
          <Typography variant='subtitle1' className={classes.detail} >Nome do titular:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1' className={classes.cardName}>{payments.cardName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1' className={classes.detail} >Número do cartão:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1' >{payments.bin.slice(0, 4)} {payments.bin.slice(4, payments.bin.length)}** **** ****</Typography>
        </Grid>
        {brandType && brandType.img &&
          <Fragment>
            <Grid item xs={6}>
              <Typography variant='subtitle1' className={classes.detail} >Bandeira:</Typography>
            </Grid>
            <Grid item xs={6}>
              <img className={classes.imgBrand} src={brandType.img} alt='brand' />
            </Grid>
          </Fragment>
        }
      </Grid>
    </Grid>
  )
}

