import React from "react";
import packageJson from '../../../package.json';
window.appVersion = packageJson.version;

const needCacheRefresh = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);

  const serverVersion = versionsA.reduce((a, b) => a+b);
  const clientVersion = versionsB.reduce((a, b) => a+b);

  if (clientVersion > serverVersion) {
    // precisa atualizar pois ocorreu rollback na versão
    return true;
  }

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
}

export default class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: async () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
          const names = await caches.keys();
          await Promise.all(names.map(name => caches.delete(name)));
        }
        window.location.reload();
      }
    };
  }

  componentDidMount() {
    const timestamp = new Date().getTime();
    fetch(`/meta.txt?t=${timestamp}`, {
      "cache": "no-cache"
    })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const latestVersionTimestamp = meta.timestamp;
        const currentVersion = window.appVersion;

        if (latestVersionTimestamp) {
          window.appVersionTimestamp = latestVersionTimestamp;
        }

        const shouldForceRefresh = needCacheRefresh(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(
            "%cWe have a new version - ${latestVersion}. Should force refresh",
            "color: white; background-color: #4CAF50; font-size: 16px; font-weight: bold; padding: 8px; border-radius: 4px;"
          );
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(
            "%cYou already have the latest version - 0.1.203. No cache refresh needed.",
            "color: white; background-color: #4CAF50; font-size: 16px; font-weight: bold; padding: 8px; border-radius: 4px;"
          );
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}
