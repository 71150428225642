import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
  Dialog,
  Button,
  CircularProgress, 
  DialogContent, 
  DialogActions, 
  DialogTitle, 
  DialogContentText, 
  useMediaQuery, 
  makeStyles
} from '@material-ui/core'
import { appIdSelector } from 'modules/Login/selectors/user'
import { FormattedMessage } from 'react-intl'
import { deleteSMS, getSMS } from '../services/sms'
import { setMessageSnack } from 'layouts/actions'
import { textIntl } from 'utils/intl'

const useStyles = makeStyles(({
  button: {
    width: 150,
    margin: 10
  },
}))

export default ({ open, setOpen, selected, setSelected }) => {

  const classes = useStyles()
  const appId = useSelector(appIdSelector)
  const dispatch = useDispatch()
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [loading, setLoading] = React.useState(false)

  const onDeleteConfirm = (event) => {
    setLoading(true)
    dispatch(deleteSMS(selected.idSmsConfiguration, appId))
      .then(result => {
        setSelected({})
        dispatch(getSMS(appId))
        handleClose()
        dispatch(setMessageSnack(textIntl('admin-send-sms-delete-success')))
      }).catch(erro => {
        dispatch(setMessageSnack(textIntl('admin-send-sms-delete-error')))
        handleClose()
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return
    setOpen(false)
    setLoading(false)
  }

  return (
    <Dialog
      disableEscapeKeyDown={true}
      maxWidth={'sm'}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <FormattedMessage id='admin-send-sms-dialog-title-delete' />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id='admin-send-sms-dialog-text' />
        </DialogContentText>
      </DialogContent>
      <DialogActions id='dialog-actions'>
        <div>
          <Button
            className={classes.button}
            disabled={loading}
            variant='contained'
            color='secondary'
            id='btn-close-modal'
            type='button'
            onClick={handleClose}>
            <FormattedMessage id='admin-send-sms-dialog-cancel' />
          </Button>
          <Button
            className={classes.button}
            disabled={loading}
            variant='contained'
            color='primary'
            id='btn-delete-confirm'
            type='button'
            onClick={onDeleteConfirm}>
            {loading ? <CircularProgress size={25} /> : <FormattedMessage id='admin-send-sms-dialog-confirm' />}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

