import React from 'react';
import "./styles.css";

function WelcomeProgress(
  {
    progress = 0,
    value = 1,
    title = 'Momento Escolar',
  }
) {
  const left = progress >= 50 ? 180 * ((progress - 50) / 50) : 0;
  const right = progress > 50 ? 180 : 180 * (progress / 50);

  return (
    <div className="welcome-progress">
      <div className="progress-title">
        <div className="progress blue" data-value={progress}>
          <span className="progress-left">
              <span
                className="progress-bar"
                style={{
                  transition: 'transform 1s ease 0s',
                  transform: `rotate(${left}deg)`
                }}
              />
          </span>
          <span className="progress-right">
              <span
                className="progress-bar"
                style={{
                  transition: 'transform 1s ease 0s',
                  transform: `rotate(${right}deg)`
                }}
              />
          </span>
          <div className="progress-value">{value}</div>
        </div>
        <h1>{title}</h1>
      </div>
    </div>
  );
}

export default WelcomeProgress;