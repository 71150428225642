import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import {getRecoil, setRecoil} from "recoil-nexus";
import {matriculasByResponsavelResponseState} from "./matriculasByResponsavelResponseState";
import {selectedMatriculaState} from "./selectedMatriculaState";

export const selectedMatriculaPortalState = atom({
  key: 'selectedMatriculaPortalState',
  default: {},
  effects: [
    persistAtomEffect,
    ({onSet}) => {
      onSet(newValue => {
        if (newValue) {
          const matriculas = getRecoil(matriculasByResponsavelResponseState);
          if (matriculas && matriculas?.value?.length > 0) {
            const matricula = matriculas?.value.find(matricula => matricula.codigoMatriculaErp === newValue.registrationid);

            if (matricula) {
              setRecoil(selectedMatriculaState, matricula);
            }
          }
        }

        return newValue;
      });
    }
  ]
});
