import {useRecoilState, useRecoilValue} from "recoil";
import {creditCardsState} from "../recoil/atoms/creditCardsState";
import {useMemo, useState} from "react";
import * as _walletService from "../service/wallet-service";
import * as mensagem from '../components/shared/Message.js';
import {selectedMatriculaState} from "../recoil/atoms/selectedMatriculaState";

export function useCardVerification(
  {
    cardId,
    handleCardSelection = () => {}
  }
) {
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const card = useMemo(() => creditCards.find(c => c.cardId === cardId), [creditCards])
  const [isVerifying, setIsVerifying] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const selectedMatricula = useRecoilValue(selectedMatriculaState);

  const handleVerifyCard = async () => {
    if (isVerifying) return;

    setIsVerifying(true);
    _walletService.cartaoVerificar(cardId, {
      sellerId: selectedMatricula?.unidade_Id,
      customerId: selectedMatricula?.responsavelPedagogico_Id,
      expirationMonth: month,
      expirationYear: year,
      matriculaId: selectedMatricula?.id
    }).then(async (resp) => {
      if (resp.ok) {
        const body = await resp.json();
        if (body.status === 'NOT VERIFIED') {
          mensagem.ExibeMensagem("Cartão não verificado. Revise as informações e tente novamente.", '2')
          return;
        }
        const cardIndex = creditCards.indexOf(card);
        const newCard = {
          ...card,
          numberToken: body?.numberToken ?? null
        };
        let list = [...creditCards];
        list[cardIndex] = newCard
        setCreditCards(list);
        handleCardSelection(newCard)
        mensagem.ExibeMensagem("Cartão verificado com sucesso!", '0')
      } else {
        mensagem.ExibeMensagem("Cartão não verificado. Revise as informações e tente novamente.", '2')
      }
    }).catch(() => {
      mensagem.ExibeMensagem("Cartão não verificado. Revise as informações e tente novamente.", '2')
    }).finally(() => {
      setIsVerifying(false)
    })
  }

  return {
    isVerifying,
    month,
    setMonth,
    year,
    setYear,
    handleVerifyCard,
  }
}
