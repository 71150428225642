import { filter, groupBy, map, uniq, replace, trim, isNull, isEqual, sortBy, values, upperCase } from 'lodash'
import moment from 'moment-timezone';
import { currency, getSchoolYear } from 'utils/utils'
import { InstallmentStatusToLabel, InstallmentStatus } from 'utils/InstallmentStatus'
import { appIdSelector, cpfCnpjSelector } from 'modules/Login/selectors/user'
import { createNegotiation, paymentRede, postAgreementBillet, postAgreementCredit } from 'modules/Negotiation/Finance/services/financial'
import { apiSuccessAction, apiErrorAction } from 'utils/API/actions/status'
import { SELECTED_EXPIRATED_FINANCIALS, REGISTRATION_EXPIRED, SELECTED_REGISTRATION_EXPIRED, CREATE_FINANCIAL, PAYMENT_CREDIT_NEGOTIATION, ACTIVE_TOUR_MODAL, STEP_TOUR_MODAL } from '../constants'
import { expirated } from 'modules/Negotiation/Finance/services/installment'
import { financialAgreementIdDataSelector } from 'modules/Negotiation/Finance/selectors/financialAgreement'
import { expiratedDataSelector } from 'modules/Negotiation/Finance/selectors/expirated'
import { MESSAGE_ERROR_PAYMENT_CREDIT } from 'modules/Finance/Pay/constants'
import { successRedeDefault } from 'modules/Finance/Pay/actions'
import state from 'utils/enums/states'
import { financialAgreementDataSelector } from 'modules/Negotiation/Finance/selectors/financialAgreement'
import numero from 'numero-por-extenso'

export const fetchExpirated = (params) => (dispatch, getState) => {
  const appid = appIdSelector(getState())
  const cpfCnpj = cpfCnpjSelector(getState())

  params = {
    ...params,
    cpfCnpj
  };

  return dispatch(expirated(appid, params)).then(result => {
    const installments = map(result, el => ({
      ...el,
      studentnameFormatted: el.studentname ? el.studentname.split(' ')[0] : '---',
      canPay: el.paymentavailability === InstallmentStatus.AVAILABLE && el.automaticdebit == false,
      viewRecept: el.paymentavailability === InstallmentStatus.WAITING_PAYMENT_PROCESSING,
      yearMonthFormatted: moment(el.transactionDate).format('MM/YY'),
      expirationdateFormatted: moment(el.expirationdate).format('DD/MM/YYYY'),
      documentvalueFormatted: currency(el.documentvalue),
      correctvalueFormatted: currency(el.correctvalue),
      automaticdebitFormatted: el.automaticdebit ? 'Sim' : 'Não',
      paymentavailabilityFormatted: InstallmentStatusToLabel(el.paymentavailability)
    }))
    let installmentsGroup = groupBy(installments, (st) => st.studentname)
    installmentsGroup = map(installmentsGroup, installment => groupBy(installment, st => st.unityid))
    const newInstallments = []
    installmentsGroup.forEach(i => map(i, i1 => (newInstallments.push(i1))))

    const registration = map(newInstallments, installment => {
      const [{ studentname, studentgender, studentid, unityname, unityid, registrationid },] = installment
      return { studentName: studentname, gender: studentgender, studentId: studentid, unityName: unityname, unityId: unityid, registrationId: registrationid }
    })
    dispatch(apiSuccessAction(  REGISTRATION_EXPIRED, registration))
    if (registration && registration[0]) {
      const [{ studentName, unityId }] = registration
      const installmentsSelected = installments.filter(installment => isEqual(installment.studentname, studentName) && isEqual(installment.unityid, unityId))
      dispatch(({
        type: SELECTED_EXPIRATED_FINANCIALS,
        data: installmentsSelected
      }))
      dispatch(setRegistrationExpiredSelected(installmentsSelected))
      return installmentsSelected
    }
    return installments
  })
}

export const fetchFinancial = (installment, items, entryPercent, payWay) => (dispatch, getState) => {
  const [item,] = items
  const itemSorted = sortBy(items, ['invoice'])
  const financialAgreementId = financialAgreementIdDataSelector(getState())

  dispatch(createNegotiation({
    _FinancialAgreementObject: {
      financialAgreementId: financialAgreementId || '',
      financialAgreementProposalId: '',
      custAccount: item.accountresponsible,
      fiscalEstablishmentId_Br: item.unityid,
      paymWayId: payWay,
      dataAreaId: item.dataareaid,
      entryPercent,
      installmentQty: installment,
      custAccountStudent: item.studentid,
      list: [],
      listMatriculas: uniq(map(itemSorted, item => item.registrationid)),
      ListInvoices: map(itemSorted, item => item.invoice),
      duedatelist: map(itemSorted, item => moment(item.expirationdate).format('YYYY-MM-DD'))
    }
  })).then((result) => {
    sessionStorage.removeItem('@PORTAL/ErroAcordo');
    if (!result.list || result.list.length === 0 || (result.list.reduce((prev, curr) => prev + Number(curr.amountCur), 0) === 0)) {
      dispatch(apiErrorAction(CREATE_FINANCIAL, 'Algo inesperado aconteceu!'))
    }
  }).catch((error) => {
     //console.log(error.response.data.message)
     //dispatch(apiErrorAction(CREATE_FINANCIAL, error.response.data.message))
     sessionStorage.setItem('@PORTAL/ErroAcordo', error.response.data.message);
  })
}

export const fetchSelectedExpirated = (studentName, unityId) => (dispatch, getState) => {
  const state = getState()
  const data = [...filter(expiratedDataSelector(state), st => isEqual(st.studentname, studentName) && isEqual(st.unityid, unityId))]

  return dispatch(({
    type: SELECTED_EXPIRATED_FINANCIALS,
    data
  }))
}

export const setRegistrationExpiredSelected = (student) => ({
  type: SELECTED_REGISTRATION_EXPIRED,
  data: student
})

export const sendPaymentCredit = (itemsAgreement, infoPayment, items, acceptanceTerm) => (dispatch, getState) => {
  const appId = appIdSelector(getState())
  const cpfCnpj = cpfCnpjSelector(getState())
  const [item,] = itemsAgreement
  const { proposalId, dataareaid, accountresponsible } = item
  const { cardNumber, cvv, expiryDateMonth, expiryDateYear, cardName, totalDiscount, isAgreement, installment, cpf, cardId } = infoPayment
  const totalToPay = parseFloat(itemsAgreement.reduce((prev, curr) => prev + Number(curr.correctvalue), 0)).toFixed(2)
  const parcelas = map(items, item => item.referenceid)

  const body = {
    parcelas,
    cardNumber: trim(replace(cardNumber, / /g, '')),
    securityCode: trim(cvv),
    cardId,
    validityMonth: expiryDateMonth,
    validityYear: expiryDateYear,
    cardHolder: cardName,
    totalToPay,
    totalDiscount: totalDiscount ? parseFloat(totalDiscount).toFixed(2) : 0,
    quantity: installment,
    datatransacao: moment().tz("America/Sao_Paulo").format('YYYY-MM-DDTHH:mm:ss'),
    proposalId: proposalId,
    isAgreement: isAgreement,
    dataAreaId: dataareaid,
    customerAccount: accountresponsible,
    appId,
    isFromNegotiation: true,
    cpf: cpf,
    acceptanceTerm: JSON.stringify(acceptanceTerm)
  }

  return dispatch(paymentRede(body, cpfCnpj)).then(result => {
    if (result.success === undefined) {

      /**Caso aconteça um erro de banco o retorno é 200 sem o response de success
       * devendo ser exibida a mensagem de sucesso com a mensagem retornada.
       */
      //region Timeout de 15 segundos
      // Adiciona timeout para adicionar um espaçamento entre as transações no cartão ,
      // provavelmente isso deve evitar que o emissor recuse operação subsequentes
      setTimeout(() => {
        dispatch(apiSuccessAction(PAYMENT_CREDIT_NEGOTIATION, isNull(result.message) ?
          successRedeDefault :
          { success: true, message: result.message, receipt: {} }))
      }, 15000)
      //endregion Timeout de 15 segundos
    } else if (!result.success) {
      dispatch(apiErrorAction(PAYMENT_CREDIT_NEGOTIATION, { message: isNull(result.message) ? MESSAGE_ERROR_PAYMENT_CREDIT : result.message }))
    }
  })
}

export const postAgreement = (params, appId, isBillet, cpfCnpj) => (dispatch) => {
  return dispatch(isBillet ? postAgreementBillet(params, appId) : postAgreementCredit(params, appId, cpfCnpj))
}

export const enableTourModalPayment = () => ({
  type: ACTIVE_TOUR_MODAL,
  data: true
})

export const disableTourModalPayment = () => ({
  type: ACTIVE_TOUR_MODAL,
  data: false
})

export const stepTourPayment = (step) => ({
  type: STEP_TOUR_MODAL,
  data: step
})

export const resetTourModalPayment = () => (dispatch) => {
  dispatch(stepTourPayment(0))
  dispatch(disableTourModalPayment())
}

export const createJsonTermAgreement = (items, total, isBillet, intl) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {

    try {
      const financialAgreement = financialAgreementDataSelector(getState());
      const [account] = financialAgreement.listCustTable;
      const [item] = items;
      const student = groupBy(items, (st) => st.registrationid);

      const getUF = () => {
        if (!account.state) {
          return ''
        }

        return state[upperCase(account.state)] || account.state;
      };

      const date = intl.formatDate(moment(), {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      });

      const weekday = intl.formatDate(moment(), { weekday: 'long' });

      const termAgreement = {
        'responsible': {
          'custName': account.custName,
          'rgNumber': account.rgNumber,
          'cpfCnpj': account.cpfCnpj,
          'custAddress': account.custAddress,
          'cep': account.zipCode,
          'state': getUF(),
        },
        'studentname': item.studentname,
        'courses': values(student).map(registration => {
          return {
            courseDescription: registration[0].courseDescription,
            contract: registration[0].registrationid,
            registrationDate: registration[0].registrationDate ? moment(registration[0].registrationDate).format('DD/MM/YYYY') : '',
            year: getSchoolYear(registration[0].schoolyear)
          }
        }),
        'invoices': map(items, (item) => {
          return {
            invoice: item.invoice,
            expirationDate: item.expirationdateFormatted,
            documentValue: item.correctvalueFormatted
          }
        }),
        'amount': currency(total),
        'descriptionAmount': `(${numero.porExtenso(total, numero.estilo.monetario)})`,
        'cardValue': currency(total),
        'cardExpiration': moment().format('DD/MM/YYYY'),
        'billets': isBillet ? map(financialAgreement.list, (item) => {
          return {
            expirationDate: moment(item.dueDate).format('DD/MM/YYYY'),
            value: currency(item.amountCur)
          }
        }) : [],
        'footerDescription': `${weekday}, ${date}`
      };

      resolve(termAgreement);
    }
    catch (error) {
      console.error(error);
    }

  });
}
