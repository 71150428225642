import React from 'react'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import RecurrencePaymentDialogCheckout from './components/RecurrencePaymentDialogCheckout'
import Close from '@material-ui/icons/Close'
import { Box, IconButton, makeStyles } from '@material-ui/core'
import { paymentSelector } from 'modules/Finance/Pay/selectors/payments'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  paperScrollPaper: {
    maxHeight: '98%',
  },
  buttonClose: {
    position: 'absolute',
    right: 0,
  },
}))

export default ({
                  open,
                  onClose,
                  setIsLoading,
                  onSuccess,
                  onError,
                  editable,
                  showInstallments = false,
                  selectedMatricula,
                  setAcceptanceTermObj,
                  isCardChanging,
                  selectedInfo,
                }) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { loading, waitingRede } = useSelector(paymentSelector)

  function handleClose() {
    onClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby='payment-dialog-title'
      classes={{ paper: !fullScreen && classes.paperScrollPaper }}
    >
      <Box display='flex' justifyContent='flex-end'>
        <IconButton
          disabled={!!(loading || waitingRede)}
          className={classNames(!fullScreen && classes.buttonClose)}
          aria-label='close-payment'
          id='ico-mdl-pag-fch'
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <RecurrencePaymentDialogCheckout
        setIsLoading={setIsLoading}
        onSuccess={onSuccess}
        onError={onError}
        editable={editable}
        selectedInfo={selectedInfo}
        showInstallments={showInstallments}
        isCardChanging={isCardChanging}
        selectedMatricula={selectedMatricula}
        setAcceptanceTermObj={setAcceptanceTermObj}
        onBack={handleClose}
      />
    </Dialog>
  )
}
