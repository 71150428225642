import React, {useState} from "react";
import classNames from "classnames";
import {currency} from "utils/utils";
import Grid from "@material-ui/core/Grid";
import Payment from "payment";
import {isEqual, omit} from "lodash";
import {Field, Form, withFormik} from "formik";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Link,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import "react-credit-cards/es/styles-compiled.css";
import useStyles from "./style";
import CustomRadio from "components/CustomRadio";
import Installments from "../Installments";
import {useSelector} from "react-redux";
import {PermissionFilter} from "components/PermissionsFilter";
import {DEBIT_FUNCTION} from "utils/permissions";
import {FormattedMessage} from "react-intl";
import WalletDialog from "../../../../components/WalletModal/WalletDialog";
import {useRecoilState} from "recoil";
import {selectedCreditCardState} from "../../../../recoil/atoms/selectedCreditCardState";

const CARD_TYPE = {
  CREDIT: "credit",
  DEBIT: "debit",
};

const PaymentForm = (props) => {
  const classes = useStyles();

  const isLoading = useSelector(props.isLoadingSelector);
  const {cardType, total, isNegotiation} = props.payment;
  const {installment} = props.values;
  const hasInstallment = props.payment.installment !== undefined;
  const totalFormatted = currency(total);
  const [selectedCard, setSelectedCard] = useRecoilState(selectedCreditCardState);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [accept, setAccept] = useState(isNegotiation ? false : true);
  const acceptTerm = () => {
    setAccept(!accept);
    props.setDisabled(accept);
  };

  const openTermAgreement = () => {
    props.openTermAgreement();
  }

  const handleChange = (event) => {
    props.setPayment({...props.payment, cardType: event.target.value});
  };

  return (
    <React.Fragment>
      <Form onSubmit={(values) => props.handleSubmit(values, props)}>
        <WalletDialog
          onSelectCard={card => {
            setSelectedCard(card)
            props.setValues({
              ...props.values,
              cardName: card.cardholder_name,
              cardId: card.card_id,
              brand: card.brand,
              numberToken: card.number_token,
              expiryDateMonth: card.expiration_month,
              expiryDateYear: card.expiration_year,
              bin: card.bin
            })
          }}
          selectedCard={selectedCard?.card_id}
        />

        <Grid
          container
          className={classes.paymentDetail}
        >
          {hasInstallment && isEqual(cardType, CARD_TYPE.CREDIT) && (
            <Installments
              installment={installment}
              {...omit(props.payment, "installment")}
            />
          )}
        </Grid>

        {!isNegotiation && (
          <KindCard cardType={cardType} handleChange={handleChange}/>
        )}

        <Divider className={classes.divider}/>

        {isNegotiation && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accept}
                  onChange={acceptTerm}
                  value='accept'
                  color='primary'
                  disabled={isLoading}
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                  }}
                />
              }
            />
            <Typography variant="body1" className={classes.labelInline}>
              Li e concordo com o <Link className={classes.link} onClick={openTermAgreement}> Termo de
              Acordo </Link> estabelecido.
            </Typography>
          </>
        )}

        <Grid
          item
          xs={12}
          align="right"
          className={classNames(isSmall && classes.buttonsSmall)}
        >
          <Typography className={classes.paymentTotal}>
            {`Valor total corrigido: ${totalFormatted}`}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading || !accept}
            id="btn-mdl-pag-cfm-rev"
            className={classes.button}
            type="submit"
          >
            {isLoading ? (
              <CircularProgress size={24} className={classes.loading}/>
            ) : (
              isNegotiation ? "Finalizar Acordo" : "Avançar"
            )}
          </Button>
        </Grid>
      </Form>
    </React.Fragment>
  );
}

const KindCard = ({cardType, handleChange}) => {
  const classes = useStyles();
  return (
    <PermissionFilter list={[DEBIT_FUNCTION]}>
      <Grid container>
        <Grid item xs={6} className={classes.cardType} md={3}>
          <Field
            name="credit"
            variant="outlined"
            checked={isEqual(cardType, CARD_TYPE.CREDIT)}
            color="primary"
            label="Crédito"
            id="ckc-mdl-pag-cred"
            value={CARD_TYPE.CREDIT}
            onClick={handleChange}
            component={CustomRadio}
          />
        </Grid>
        <Grid item sm xs={6} className={classes.cardType} md={3}>
          <Field
            name="debit"
            variant="outlined"
            checked={isEqual(cardType, CARD_TYPE.DEBIT)}
            id="ckc-mdl-pag-deb"
            value={CARD_TYPE.DEBIT}
            color="primary"
            label="Débito"
            onClick={handleChange}
            component={CustomRadio}
          />
        </Grid>
        {isEqual(cardType, CARD_TYPE.DEBIT) && (
          <Grid item xs={12}>
            <FormattedMessage id="payment-form-card-warning"/>
            <ul className={classes.warningText}>
              <li>
                <FormattedMessage id="payment-form-card-warning-text1"/>
              </li>
              <li>
                <FormattedMessage id="payment-form-card-warning-text2"/>
              </li>
              <li>
                <FormattedMessage id="payment-form-card-warning-text3"/>
              </li>
            </ul>
          </Grid>
        )}
      </Grid>
    </PermissionFilter>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    cardName: props.payment.cardName,
    cardId: props.payment.cardId,
    brand: props.payment.brand,
    cardNumber: props.payment.cardNumber,
    expiryDateMonth: props.payment.expiryDateMonth,
    expiryDateYear: props.payment.expiryDateYear,
    cvv: props.cvv || "",
    installment: props.payment.installment,
    cpf: props.payment.cpf,
  }),
  handleSubmit: (values, props) => {
    const {expiryDateMonth, expiryDateYear, cardNumber} = values
    if (Payment.fns.validateCardExpiry(expiryDateMonth, expiryDateYear)) {
      props.props.setPayment({
        ...props.props.payment,
        ...values
      })
      props.props.handleNext({
        ...props.props.payment,
        ...values
      })
    } else {
      props.setErrors({...props.errors, expiryDateMonth: 'Mês inválido'})
      props.setSubmitting(false)
    }
  }
})(PaymentForm);
