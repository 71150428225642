import React from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Payment from 'payment'
import {Form, withFormik} from 'formik'
import * as yup from 'yup'
import {Button, useMediaQuery} from '@material-ui/core'
import 'react-credit-cards/es/styles-compiled.css'
import useStyles from './style'
import WalletDialog from "../../../../../../../components/WalletModal/WalletDialog";
import {useRecoilState} from "recoil";
import {selectedCreditCardState} from "../../../../../../../recoil/atoms/selectedCreditCardState";


const RecurrencePaymentDialogForm = (props) => {
  const {
    handleBack,
  } = props
  const classes = useStyles()
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const [selectedCard, setSelectedCard] = useRecoilState(selectedCreditCardState);

  console.log("selectedCard", selectedCard)

  return (
    <Grid
      container
      justifyContent='center'
    >
      <Form
        style={{
          width: '100%'
        }}
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (selectedCard?.card_id) {
            props.handleSubmit(event, props)
          }
        }}
      >
        <Grid container justifyContent='center'>
          <Grid item xs={12} md={6} className={classes.card}>
            <WalletDialog
              onSelectCard={card => {
                setSelectedCard(card)
                props.setValues({
                  ...props.values,
                  cardId: card.card_id,
                  cardName: card.cardholder_name,
                  expiryDateMonth: card.expiration_month,
                  brand: card.brand,
                  expiryDateYear: card.expiration_year,
                  bin: card.bin,
                  numberToken: card.number_token,
                })
              }}
              selectedCard={selectedCard?.card_id}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          align='right'
          className={classNames(isSmall && classes.buttonsSmall)}
        >
          <Button
            id='btn-mdl-pag-cfm-rev'
            className={classes.button}
            type='button'
            onClick={handleBack}
          >
            Voltar
          </Button>
          <Button
            variant='contained'
            color='primary'
            id='btn-mdl-pag-cfm-rev'
            className={classes.button}
            type='submit'
          >
            Avançar
          </Button>
        </Grid>
      </Form>
    </Grid>
  )
}

export default withFormik({
  mapPropsToValues: (props) => ({
    cardName: props.payment.cardName,
    cardId: props.payment.cardId,
    bin: props.payment.bin,
    numberToken: props.payment.numberToken,
    expiryDateMonth: props.payment.expiryDateMonth,
    expiryDateYear: props.payment.expiryDateYear,
    installment: props.payment.installment,
  }),
  handleSubmit: (values, props) => {
    const {expiryDateMonth, expiryDateYear} = values

    if (Payment.fns.validateCardExpiry(expiryDateMonth, expiryDateYear)) {
      props.props.setPayment({
        ...props.props.payment,
        ...values,
      })

      props.props.handleNext({
        ...props.props.payment,
        ...values,
      })
    } else {
      props.setErrors({...props.errors, expiryDateMonth: 'Mês inválido'})
      props.setSubmitting(false)
    }
  },
})(RecurrencePaymentDialogForm)
