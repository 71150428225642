import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import acceptedCards from "../../utils/acceptedCards";
import Card from "react-credit-cards";
import {Card as CardBootStrap, Col, Container, ListGroup, Row} from 'react-bootstrap';
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import WelcomeProgress from "../WelcomeProgress/WelcomeProgress";
import WalletSelectionPreview from "./WalletSelectionPreview";
import {walletCardChangeSubsState} from "../../recoil/atoms/walletCardChangeSubsState";
import {walletCardSubsState} from "../../recoil/atoms/walletCardSubsState";
import WalletCardsList from "./WalletCardsList";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import * as mensagem from '../../components/shared/Message.js';
import * as _walletService from "../../service/wallet-service";
import * as _matriculaService from '../../service/matricula-service.js';
import PageLoading from "../PageLoading/PageLoading";
import WalletContractList from "./WalletContractList";
import {walletContractsCheckedState} from "../../recoil/atoms/walletContractsCheckedState";

function WalletChangeSubs(
  {
    onCancel = () => {
    }
  }
) {
  const {
    cardId,
    lastFourDigits = '',
    expirationYear = '',
    expirationMonth = '',
    cardholderName = '',
    brand = '',
  } = useRecoilValue(walletCardChangeSubsState);
  const originalCard = useRecoilValue(walletCardChangeSubsState);
  const [step, setStep] = React.useState(1);
  const previewLabel = "Selecione o novo cartão para esta recorrência";
  const cardNumber = `**** **** **** ${lastFourDigits}`;
  const [isSaving, setIsSaving] = React.useState(false);
  const subs = useRecoilValue(walletCardSubsState);
  const setSubs = useSetRecoilState(walletCardSubsState);
  const [showCardSelection, setShowCardSelection] = React.useState(false);
  const [selectedCreditCard, setSelectedCreditCard] = React.useState(undefined);
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const [selectedSub, setSelectedSub] = React.useState(undefined);
  const [matriculas, setMatriculas] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = useRecoilState(walletContractsCheckedState);

  useEffect(() => {
    if (loading) return;

    setLoading(true);

    _matriculaService.GetbyIdResponsavel(sessionStorage.getItem("@SMO20/idResponsavel"))
      .then(resp => {
        if (resp && resp?.value) {
          setMatriculas(resp.value);
        }
      }).finally(() => setLoading(false))
  }, [])

  const onSubmit = async (event) => {
    event.preventDefault();

    if (step === 1) {
      const changedAllSubs = subs.find(s => s.subscription.payment_type.credit.card.card_id === cardId);

      if (changedAllSubs !== undefined) {
        mensagem.ExibeMensagem(`Altere o cartão de todas as recorrências para continuar.`, '2')
        return false;
      }

      setChecked([]);
      setStep(2)
      return;
    }

    if (isSaving) return;

    setIsSaving(true);

    let sellerId = sessionStorage.getItem('@SMO20/unidade_Id');

    _walletService.cartaoAlterarAssinaturas(sellerId, cardId, subs)
      .then(resp => {
        if (resp.ok) {
          mensagem.ExibeMensagem(`Alteração realizada com sucesso!`, '0')
          onCancel();
        } else {
          mensagem.ExibeMensagem(`Não foi possível realizar a alteração.`, '2')
        }
      }).finally(() => setIsSaving(false))

    return false;
  }

  const handleBack = () => {
    if (showCardSelection) {
      setShowCardSelection(false);
    } else if(onCancel) {
      onCancel()
    }
  }

  const handleSelectCard = (cardId, sub) => () => {
    const card  = creditCards.find(c => c.cardId === cardId);
    setShowCardSelection(true);
    setSelectedCreditCard(card);
    setSelectedSub(sub);
  }

  const handleCardSelection = (selectedCard) => {
    const indexOfSub = subs.indexOf(selectedSub);
    const newSubs = [...subs];

    newSubs[indexOfSub] = {
      ...selectedSub,
      subscription: {
        ...selectedSub.subscription,
        payment_type: {
          ...selectedSub.subscription.payment_type,
          credit: {
            ...selectedSub.subscription.payment_type.credit,
            card: {
              cardholder_name: selectedCard.cardholderName,
              expiration_month: selectedCard.expirationMonth,
              last_four_digits: selectedCard.lastFourDigits,
              expiration_year: selectedCard.expirationYear,
              brand: selectedCard.brand,
              card_id: selectedCard.cardId
            }
          }
        }
      }
    };

    setSubs(newSubs);
    setShowCardSelection(false);
    setSelectedCreditCard(undefined);
    setSelectedSub(undefined);
  }

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return changeCardStep();
      case 2:
        return acceptStep();
      default:
        return null;
    }
  }

  const changeCardStep = () => {
    return (
      <>
        <WelcomeProgress
          progress={50}
          value={step}
          title="Substitua o cartão atual"
        />

        <div className="mb-3">
          <Card
            number={cardNumber}
            name={cardholderName}
            expiry={`${expirationMonth}/${expirationYear}`}
            acceptedCard={acceptedCards}
            preview={true}
            placeholders={{name: 'Nome impresso no cartão'}}
            locale={{valid: 'BR'}}
            issuer={brand}
          />
        </div>

        {showCardSelection ? (
          <CardBootStrap>
            <CardBootStrap.Body>
              <WalletCardsList
                hiddenAddCard
                selectedCreditCard={selectedCreditCard}
                showRemove={false}
                onClickSelectCard={handleCardSelection}
              />
            </CardBootStrap.Body>
          </CardBootStrap>
        ) : (
          <ListGroup>
            {(subs || []).map(sub => {
              const {
                order_id,
                plan: {
                  name = "",
                },
                subscription: {
                  payment_type: {
                    credit: {
                      card: {
                        cardholder_name,
                        expiration_month,
                        last_four_digits,
                        expiration_year,
                        brand,
                        card_id,
                        bin
                      }
                    }
                  }
                }
              } = sub;
              let selected = undefined;

              if (card_id !== cardId || !(bin === originalCard?.bin && expiration_month === originalCard?.expirationMonth && expiration_year === originalCard?.expiration_year)) {
                selected = {
                  cardId: card_id,
                  lastFourDigits: card_id === cardId ? lastFourDigits : last_four_digits,
                  expirationYear: expiration_year,
                  expirationMonth: expiration_month,
                  cardholderName: cardholder_name,
                  brand: String(brand).toLowerCase(),
                  numberToken: null,
                  isExpired: false,
                  canRemove: false
                }
              }
              let nome = " - ";

              if (matriculas && matriculas.length) {
                let matricula = (matriculas || []).find(m => m?.codigoMatricula === order_id);

                if (matricula) {
                  nome = matricula?.nomeLandingPage || matricula?.servico_Name;
                }
              }

              return (
                <ListGroup.Item>
                  Serviço: {nome && <>{nome} <br/></>}
                  Plano: {name ?? ""} <br/>
                  <WalletSelectionPreview
                    card={selected}
                    label={previewLabel}
                    onClick={handleSelectCard(card_id, sub)}
                  />
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        )}
      </>
    )
  }

  const acceptStep = () => {
    const progress = (checked.length * 100 / subs.length) / 2;
    const step1progress = 50;
    return (
      <>
        <WelcomeProgress
          progress={progress + step1progress}
          value={step}
          title="Aceite"
        />

        {matriculas !== null && (
          <WalletContractList
            matriculas={matriculas}
          />
        )}
      </>
    )
  }

  if (loading) return <PageLoading/>

  return (
    <span>
      <Container className="mt-3">
        {renderStep(step)}
      </Container>

      <Row className="mt-5">
        <Col>
          <button
            className="btn btn-secondary"
            onClick={handleBack}
            type="button"
            disabled={isSaving}
          >
            Voltar
          </button>
        </Col>

        <Col
          className="text-right"
        >
          <button
            className="btn btn-primary"
            type="button"
            disabled={isSaving}
            onClick={onSubmit}
          >
            {isSaving ? <LoadingAnimation/> : step === 2 ? "Finalizar" : "Próxima etapa"}
          </button>
        </Col>
      </Row>
    </span>
  )
}

export default WalletChangeSubs;
