import React, { forwardRef } from 'react'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { useTheme } from '@material-ui/styles'
import { Paper } from '@material-ui/core'

export const defaultIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} style={{ margin: 0 }} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

export const defaultOptions = {
  search: true,
  paging: false,
  sorting: true,
  draggable: false,
  emptyRowsWhenPaging: false,
  actionsColumnIndex: -1,
}

const Table = ({ options, columns, components, localization, elevation, ...rest }) => {
  const [pageSize, setPageSize] = React.useState(5)

  const theme = useTheme()

  return <MaterialTable
    onChangeRowsPerPage={setPageSize}
    icons={defaultIcons}
    options={{
      pageSize: pageSize,
      ...defaultOptions,
      ...options,
      headerStyle: {
        color: theme.palette.primary.main,
        fontSize: 15,
        paddingRight: 5,
        lineHeight: '1rem',
        textAlign: 'center',
        backgroundColor: '#f0f0f1',
        ...options.headerStyle,
      }
    }}
    components={{
      Container: (props) => <Paper {...props} elevation={elevation ?? 1} />,
      ...components,
    }}
    localization={{
      pagination: {
        labelDisplayedRows: '{from}-{to} de {count}',
        firstTooltip: 'Primeira',
        nextTooltip: 'Próxima',
        lastTooltip: 'Última',
        previousTooltip: 'Anterior',
        labelRowsPerPage: 'Itens',
        labelRowsSelect: 'itens'
      },
      toolbar: {
        nRowsSelected: '{0} parcelas',
        searchPlaceholder: 'Buscar'
      },
      header: {
        actions: 'Ações'
      },
      body: {
        emptyDataSourceMessage: 'Nenhum item encontrado',
        filterRow: {
          filterTooltip: 'Filtrar'
        },
      },
      ...localization
    }}
    columns={columns.map(col => ({
      ...col,
      cellStyle: { fontSize: 13,paddingRight: 5, textAlign: 'center', ...col.cellStyle }
    }))}
    {...rest}
  />
}

export default Table