import React, {useMemo} from "react";
import SearchInput from "../SearchInput/SearchInput";
import classNames from "../../utils/classNames";
import CardSelection from "./CardSelection";
import includesSearch from "../../utils/includesSearch";
import {useRecoilState, useRecoilValue} from "recoil";
import {walletModalLayoutState} from "../../recoil/atoms/walletModalLayoutState";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import * as _walletService from "../../service/wallet-service";

function WalletCardsList(
  {
    hidden = false,
    onClickAddCard = () => {},
    onClickSelectCard = (card) => {},
    selectedCreditCard,
    hiddenAddCard = false,
    showRemove
  }
) {
  const creditCards = useRecoilValue(creditCardsState);
  const [walletLayout, setWalletLayout] = useRecoilState(walletModalLayoutState);
  const [search, setSearch] = React.useState('');
  const filteredCreditCards = useMemo(() => creditCards.filter(card => {
    return includesSearch(JSON.stringify(card), search);
  }), [creditCards, search]);

  const handleSearch = (value) => {
    setSearch(value);
  }

  const handleWalletLayout = (layout) => () => {
    setWalletLayout(layout);
  }

  const handleClickAddCard = () => {
    if (onClickAddCard) onClickAddCard();
  }

  const handleClickSelectCard = card => {
    if (onClickSelectCard) onClickSelectCard(card);
  }

  return (
    <div style={{display: hidden ? "none" : "block"}}>
      <div className="smo-wallet-modal-header">
        <SearchInput
          onSearch={handleSearch}
        />

        <div className="smo-wallet-modal-header-options">
          <button
            className={classNames(
              "smo-wallet-modal-header-btn",
              walletLayout === 'row' && 'active'
            )}
            type="button"
            onClick={handleWalletLayout('row')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="currentColor"
            >
              <title>Visualizar em lista</title>
              <path d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z" />
            </svg>
          </button>

          <button
            className={classNames(
              "smo-wallet-modal-header-btn",
              walletLayout === 'card' && 'active'
            )}
            type="button"
            onClick={handleWalletLayout('card')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
              fill="currentColor"
            >
              <title>Visualizar em grade</title>
              <path d="M13,3V9H21V3M13,21H21V11H13M3,21H11V15H3M3,13H11V3H3V13Z" />
            </svg>
          </button>
        </div>
      </div>

      <div
        className={classNames(
          "smo-wallet-modal-body",
          walletLayout === 'row' && 'smo-wallet-modal-body-row',
        )}
      >
        {!hiddenAddCard && (
          <button
            className="smo-wallet-modal-add-card-button"
            onClick={handleClickAddCard}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="38"
              height="38"
              fill="currentColor"
            >
              <title>Novo cartão</title>
              <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
            </svg>
            <span>Adicionar novo cartão</span>
          </button>
        )}

        {(creditCards.length > 0) && (
          <React.Fragment>
            {filteredCreditCards.map(card => (
              <CardSelection
                key={card.cardId}
                card={card}
                onClick={onClickSelectCard ? handleClickSelectCard : null}
                type={walletLayout}
                selected={selectedCreditCard && selectedCreditCard.cardId === card.cardId}
                showButton={!(selectedCreditCard && selectedCreditCard.cardId === card.cardId)}
                showRemove={showRemove}
                buttonLabel={selectedCreditCard && selectedCreditCard.cardId === card.cardId ? 'Selecionado' : 'Selecionar'}
              />
            ))}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default WalletCardsList;
