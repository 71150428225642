import React, {Fragment, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Box, useMediaQuery} from '@material-ui/core'
import {makeStyles, useTheme} from '@material-ui/styles'
import Table from 'components/Table'
import {MTableAction} from 'material-table'
import {accessIdSelector, appIdSelector, cpfCnpjSelector, userSelectedSelector} from 'modules/Login/selectors/user'
import ReceiptDialog from '../components/ReceiptDialog'
import {fetchClosed, resetPaymentClosed} from './actions'
import {closedDataSelector, isLoadingClosedSelector} from './selectors/closed'
import {otherColumns, otherMediumColumns, smColumns} from './constants'
import CustomCellHighlight from 'components/CustomCellHighlight'


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {TableChartTwoTone} from "@material-ui/icons";

const useStylesAccordion = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionDetails: {
    display: 'block'
  }
}));

const CustomAction = (props) => {
  const { id } = props.data.tableData
  return <div id={id}>
    <MTableAction {...props} />
  </div>
}

const defaultReceipt = { open: false, correctvalue: 0 }

const Paid = () => {
  const appid = useSelector(appIdSelector)
  const dispatch = useDispatch()
  const data = useSelector(closedDataSelector)
  const closedThisYear = useMemo(() => (data || []).filter(item => item.transactionDate.split('-')[0] === new Date().getFullYear().toString()), [data]);
  const closed = useMemo(() => (data || []).filter(item => item.transactionDate.split('-')[0] !== new Date().getFullYear().toString()), [data]);
  const isLoadingData = useSelector(isLoadingClosedSelector)
  const accessId = useSelector(accessIdSelector)
  const cpfCnpj = useSelector(cpfCnpjSelector)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const tableRef = React.useRef()
  const [openModal, setOpenModal] = React.useState(defaultReceipt)
  const [isFirst, setIsFirst] = React.useState(true)
  const userSelected = useSelector(userSelectedSelector)
  const classesAccordion = useStylesAccordion()

  useEffect(() => {
    try {
      if (isFirst) {
        tableRef.current.unity = 0
        setIsFirst(false)
        dispatch(resetPaymentClosed())
        return
      }
    } catch (e) {
      console.error(e)
    }
  }, [appid, data])

  // It control the refresh when change the accessId
  useEffect(() => {
    dispatch(fetchClosed({ accessId, cpfCnpj }))
  }, [accessId, dispatch, userSelected])

  const columns = [
    ...smColumns,
    ...(isMedium ? [] : otherMediumColumns),
    ...(isSmall ? [] : otherColumns)
  ]


  return <Fragment>
    <ReceiptDialog
      {...openModal}
      onClose={() => setOpenModal(defaultReceipt)}
    />
    <Table
      isLoading={isLoadingData}
      components={{
        Action: CustomAction,
        Cell: (props) => <CustomCellHighlight {...props} tableref={tableRef} />
      }}
      tableRef={tableRef}
      columns={columns}
      options={{
        selection: false,
        headerStyle: {
          paddingRight: 5,
          padding: '16px 0px',
        }
      }}
      data={closedThisYear}
      title={'Parcelas liquidadas'}
    />

    {!isLoadingData && (
      <Box
        sx={{
          mt: 4
        }}
      >
        <Accordion
          defaultExpanded={closedThisYear?.length === 0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classesAccordion.heading}>Parcelas de anos anteriores</Typography>
          </AccordionSummary>

          <AccordionDetails
            className={classesAccordion.accordionDetails}
          >
            <Table
              elevation={0}
              isLoading={isLoadingData}
              components={{
                Action: CustomAction,
                Cell: (props) => <CustomCellHighlight {...props} tableref={tableRef} />
              }}
              tableRef={tableRef}
              columns={columns}
              options={{
                selection: false,
                headerStyle: {
                  paddingRight: 5,
                  padding: '16px 0px',
                }
              }}
              data={closed}
              title={'Parcelas liquidadas'}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    )}
  </Fragment>
}

export default Paid
