export function cartaoSalvar(card) {
    return fetch(`/api/wallet/card`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(card),
    }).then(resp => {
      return new Promise(resolve =>  {
        setTimeout(() => {
          resolve(resp);
        }, 15000);
      })
    })
}

export function cartaoVerificar(cardId, card) {
  return fetch(`/api/wallet/card/${cardId}/verify`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(card),
  }).then(resp => {
    return new Promise(resolve =>  {
      setTimeout(() => {
        resolve(resp);
      }, 15000);
    })
  })
}

export function cartaoApagar(unidadeId, cardId) {
  return fetch(`/api/wallet/card/${cardId}?sellerId=${unidadeId}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(resp => {
    return new Promise(resolve =>  {
      setTimeout(() => {
        resolve(resp);
      }, 15000);
    })
  })
}

export function cartaoAssinaturas(unidadeId, cardId) {
  return fetch(`/api/wallet/card/${cardId}/subscriptions?sellerId=${unidadeId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export function cartaoAlterarAssinaturas(unidadeId, cardId, subs) {
  return fetch(`/api/wallet/card/${cardId}/subscriptions?sellerId=${unidadeId}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(subs),
  }).then(resp => {
    return new Promise(resolve =>  {
      setTimeout(() => {
        resolve(resp);
      }, 15000);
    })
  })
}

export function cartaoListar(unidadeId, responsavelId) {
  return fetch(`/api/wallet/cards?sellerId=${unidadeId}&customerId=${responsavelId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error) => {
      console.error(error.message);
    });
}
