import fetch from "../utils/fetch";
import env from "../env";

export function Get(tabela, campo) {

    return fetch(env.apiUrl + "api/ConjuntoOpcao/Get/" + tabela + "/" + campo, {
        method: "GET",
        mode: "cors",
    }).then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            console.log(error.message);
            return false;
        });
}


