import React, { useState } from "react";
import { Provider, useSelector, useDispatch } from 'react-redux'
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import { Form, Field, withFormik } from "formik";
import * as yup from "yup";
import { Button, useMediaQuery, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "react-credit-cards/es/styles-compiled.css";
import useStyles from "./style";
import banks from "../../data/banks.json";
import bankscarol from "../../data/bankscarol.json";
import MaskedInput from "react-text-mask";
import {
  companyInformationDataSelector,
  hasLoadedCompanyInformationsSelector,
} from "modules/Negotiation/Finance/selectors/companyInformations";
import { appIdSelector, appNameSelector, accessSelector, userAppUsername, userUnitiesSelector } from "modules/Login/selectors/user";
import {getCompanyInformations} from "../../../../../../Negotiation/Finance/services/company";

const MyFormWithFormik = withFormik({
  mapPropsToValues: (props) => ({
    agency: props.automaticDebit.agency,
    bank: props.automaticDebit.bank,
    cc: props.automaticDebit.cc,
    comPany: props.company,
  }),

  validationSchema: yup.object().shape({
    agency: yup.string().required("Obrigatório"),
    bank: yup.object().required("Obrigatório").nullable(),
    cc: yup.string().required("Obrigatório"),
  }),

  handleSubmit: (values, props) => {
    if (typeof values.bank == "string") {
      props.setErrors({ ...props.errors, bank: "Obrigatório" });
    } else {
      const onlyNumbersAgency = values.agency.replace(/\D/g, "");

      if (
        (values.bank.value == "237" &&
          onlyNumbersAgency.toString().length != 5) ||
        (values.bank.value != "237" && onlyNumbersAgency.toString().length != 4)
      ) {
        props.setErrors({ ...props.errors, agency: "Agência inválida" });
      } else {
        props.props.setAutomaticDebit({
          ...props.props.automaticDebit,
          ...values,
        });

        props.props.handleNext({
          ...props.props.automaticDebit,
          ...values,
        });
      }
    }
  },
});

export default MyFormWithFormik((props) => {
  const dispatch = useDispatch();
  const appId = useSelector(appIdSelector);
  const unities = useSelector(userUnitiesSelector);
  const hasLoadedCompany = useSelector(hasLoadedCompanyInformationsSelector);
  const company = useSelector(companyInformationDataSelector);


  React.useEffect(() => {
    if (unities && unities.length > 0 && !window.location.pathname.includes('login') && !hasLoadedCompany) {
      dispatch(getCompanyInformations(appId, unities[unities.length - 1]));
    }
  }, [unities]);


  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isLoading = false;

  const [defaultBank, setDefaultBank] = useState(props.automaticDebit.bank);
  const [selectedBank, setSelectedBank] = useState(props.automaticDebit.bank);

  const onChange = (event, selectedObject) => {
    props.setFieldValue("agency", "");
    props.setFieldValue("cc", "");
    if (selectedObject) {
      setSelectedBank(selectedObject);
      props.setFieldValue("bank", selectedObject);
    } else {
      setSelectedBank("");
      props.setFieldValue("bank", "");
    }
  };

  const onChangeCC = (event) => {
      const { value } = event.target;
      const valueString = value.toString();
      if (valueString.length <= 20) {
          props.setFieldValue("cc", event.target.value.replace(/[^0-9]/g, ""));
      }
  };

  return (
    <React.Fragment>
      <Grid container justify="center">
        <Form
          onSubmit={(values) => props.handleSubmit(values, props)}
          disabled={false}
        >
          <Grid item xs={12} className={classes.item} md={12}>
            <Autocomplete
              options={('ECP' == company.dataAreaId || 'ECR' == company.dataAreaId) ? bankscarol : banks}
              getOptionLabel={(option) => `${option.value} - ${option.text}`}
              noOptionsText="Nenhum resultado"
              onChange={onChange}
              defaultValue={defaultBank}
              clearOnBlur={true}
              renderInput={(params) => (
                <Field
                  {...params}
                  variant="outlined"
                  fullWidth
                  name="bank"
                  id="form-bank"
                  label="Banco *"
                  InputLabelProps={{ shrink: true }}
                  component={TextField}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className={classes.item} md={5}>
            <Field
              name="agency"
              variant="outlined"
              id="form-agency"
              autoComplete="off"
              disabled={false}
              InputLabelProps={{ shrink: true }}
              label={
                selectedBank && selectedBank.value == "237"
                  ? "Agência com o dígito *"
                  : "Agência *"
              }
              fullWidth
              component={TextField}
              type={
                selectedBank && selectedBank.value == "237" ? "text" : "text"
              }
              InputProps={{
                classes: { input: classes.input },
                inputComponent:
                  selectedBank && selectedBank.value == "237"
                    ? AgencyMaskWithCode
                    : AgencyMask,
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.item} md={7}>
            <Field
              name="cc"
              // onClick={onFocus}
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              id="form-cc"
              label="Número da conta com dígito *"
              disabled={false}
              component={TextField}
              type="text"
              onKeyUp={onChangeCC}
              InputProps={{
                    classes: { input: classes.input },
                    inputComponent: CurrentAccount,
                }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            align="right"
            className={classNames(isSmall && classes.buttonsSmall)}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              id="btn-mdl-pag-cfm-rev"
              className={classes.button}
              type="submit"
            >
              {isLoading ? (
                <CircularProgress size={24} className={classes.loading} />
              ) : (
                "Avançar"
              )}
            </Button>
          </Grid>
        </Form>
      </Grid>
    </React.Fragment>
  );
});

function AgencyMaskWithCode(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/]}
      guide={false}
    />
  );
}

function AgencyMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      guide={false}
    />
  );
}

function CurrentAccount(props) {
    const { inputRef, ...other } = props;

    const getMask = (value) => {
        const length = value.replace(/\D/g, '').length;

        if (length > 5 && length < 19) {
            return new Array(length-1).fill(/[0-9]/).slice(0, length).concat(['-', /[0-9]/]);
        } else {
            return new Array(19).fill(/[0-9]/).concat(['-', /[0-9]/]);
        }
    };

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={getMask}
            guide={false}
        />
    );
}
