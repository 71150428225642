import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import routes, { PATH_ROUTE } from '../../routes';
import { hasAny, DASHBOARD } from 'utils/permissions';
import { permissionsSelector } from 'modules/Login/selectors/user';
import { useSelector } from 'react-redux';

const VerifyPermissionsAndLoggedUser = ({ component, children }) => {
    const sessionId = sessionStorage.getItem("@SMO20/idResponsavel");
    const sessionUsername = sessionStorage.getItem("@SMO20/nomeResponsavel");
    const permissions = useSelector(permissionsSelector);
    const isUserLoggedIn = (sessionId && sessionUsername) ? true : false;
    const hasRequiredPermissions = hasAny(permissions, [DASHBOARD]);

    const currentRoute = routes.find(route => route.path === window.location.pathname);
    const routeRequiresPermissions = currentRoute && currentRoute.permissions;
    if (isUserLoggedIn || window.location.pathname === '/' || window.location.pathname.includes('returnTo') || window.location.pathname.includes('validar-carteirinha') || window.location.pathname === '/RedefinirSenha' || window.location.pathname === '/CriarSenha' || window.location.pathname === '/LoginExternal') {
        if (routeRequiresPermissions && hasRequiredPermissions) {
            return <Route component={component} render={() => children} />;
        } else if (routeRequiresPermissions && !hasRequiredPermissions) {
            return <Redirect to={PATH_ROUTE.CADASTRO_CONCLUIDO} />
        } else {
            return children;
        }
    } else {
        const queryParams = new URLSearchParams({ returnTo: window.location.pathname });
        const queryString = queryParams.toString();
        return (
            <Redirect to={isUserLoggedIn ? PATH_ROUTE.HOME_SMO : `/?${queryString}`} />
        );
    }
};

export default VerifyPermissionsAndLoggedUser;