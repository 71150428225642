import React, { Fragment } from "react";
import classNames from "classnames";
import {
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import useStyles from "./style";

export default ({ open, onClose, discount = 0 }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function handleClose(event, reason) {
    if (reason === "backdropClick") return;
    onClose();
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="automatic-debit-dialog-title"
      classes={{ paper: !fullScreen && classes.paperScrollPaper }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          className={classNames(!fullScreen && classes.buttonClose)}
          aria-label="close-payment"
          id="ico-mdl-pag-fch"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <Grid
        container
        justify="space-between"
        direction="row"
        alignItems="center"
        className={classes.content}
      >
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            Pagamento Básico
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.body}>
          <Typography className={classes.subtitle}>Boleto</Typography>

          <Typography>
            Ao escolher esta forma de pagamento, você deverá acessar mensalmente
            nosso portal financeiro e fazer o download do seu boleto.
          </Typography>

          <br />

          <Typography>
            Os boletos podem ser pagos em redes bancárias, lotéricas ou via
            aplicativos de banco e pagamentos.
          </Typography>

          <br />

          <Typography>
            <b>⚠</b> Como <b>não é um pagamento automático</b>, demanda atenção
            com a data de pagamento.
          </Typography>

          <br />

          <Typography>
            Lembrando que o prazo de compensação do boleto é de 3 dias úteis.
          </Typography>

          <Typography>
            Após a conclusão da operação haverá período de carência para nova
            alteração.
          </Typography>

          <br />
          <br />

          <Typography className={classes.subtitle}>
            Cartão de crédito
          </Typography>

          <Typography>
            Nesta opção, o pagamento <b>não é automático.</b> Mensalmente, na
            data de vencimento, você acessa nosso portal financeiro, insere os
            dados do seu cartão e realiza o pagamento.
          </Typography>

          <br />

          <Typography>
            <b>⚠</b> Como o pagamento não é automático, essa opção demanda maior
            atenção com a data de pagamento.
          </Typography>

          <br />

          <Typography>
            <b>✈</b> Assim como na opção crédito recorrente, você pode acumular
            pontos em programas de milhagens que participar.
          </Typography>

          <br />

          <Typography>
            Após a conclusão da operação haverá período de carência para nova
            alteração.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.buttons}>
          <Button
            onClick={handleClose}
            className={classes.button}
            variant="outlined"
            color="secondary"
          >
            Fechar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
